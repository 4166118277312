const state = {
    keepAliveTags: []
  }
  const mutations = {
    SET_KEEPALIVE_TAG (state, data) {
      let idx = state.keepAliveTags.indexOf(data)
      if (idx < 0) {
        state.keepAliveTags.push(data)
      }
    },
    REMOVE_KEEPALIVE_TAG (state, data) {
      let idx = state.keepAliveTags.indexOf(data)
      if (idx > -1) {
        state.keepAliveTags.splice(idx, 1)
      }
    }
  }
  const actions = {
    setKeepAliveTag ({ commit }, data) {
      commit('SET_KEEPALIVE_TAG', data)
    },
    removeKeepAliveTag ({ commit }, data) {
      commit('REMOVE_KEEPALIVE_TAG', data)
    }
  }
  const getters = {
    keepAliveTags: state => {
      return state.keepAliveTags
    }
  }
   
  // 不要忘记把state, mutations等暴露出去。
  export default {
    state,
    mutations,
    actions,
    getters
  }