<template>
  <div class="footer">
    <div class="contact">
      <h4 v-if="$store.getters.sysConfigs.COMPANY_NAME">{{ $store.getters.sysConfigs.COMPANY_NAME }}</h4>
      <p v-if="$store.getters.sysConfigs.COMPANY_ADDRESS">{{ $store.getters.sysConfigs.COMPANY_ADDRESS }}</p>
      <p v-if="$store.getters.sysConfigs.COMPANY_ADDRESS_BEIJINNG">{{ $store.getters.sysConfigs.COMPANY_ADDRESS_BEIJINNG }}</p>
      <p v-if="$store.getters.sysConfigs.COMPANY_TEL">{{ $store.getters.sysConfigs.COMPANY_TEL }}</p>
      <!-- <p>官方网站：www.sclinker.com</p> -->
      <p>
        <span v-if="$store.getters.sysConfigs.COMPANY_STARTYEAR">{{ $store.getters.sysConfigs.COMPANY_STARTYEAR }}-{{new Date().getFullYear() }}</span>
        <span v-if="$store.getters.sysConfigs.COMPANY_NO"><a href="https://beian.miit.gov.cn/" target="_blank"> {{ $store.getters.sysConfigs.COMPANY_NO }}</a></span>
      </p>
    </div>
    <div class="qr" v-if="$store.getters.sysConfigs.COMPANY_QR">
      <img src="../assets/img/QR.jpg" alt="">
    </div>
  </div>
</template>

<style lang="less" scoped>
/* 屏幕大于1200px显示的颜色 */
.footer {
    display: flex;
  }
  .contact {
    margin: 0 0 0 10%;
    width: 60%;
  }
  .qr {
    width: 20%;
    margin: 0 10% 0 0;
    padding: 30px 0 20px 0;
  }
/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width:1200px) {
  .footer {
    display: flex;
  }
  .contact {
    margin: 0 0 0 10%;
    width: 60%;
  }
  .qr {
    width: 20%;
    margin: 0 10% 0 0;
    padding: 30px 0 20px 0;
  }
}
/* 小屏幕 768px-992px*/
@media screen and (max-width:992px) {
  .footer {
    display: flex;
  }
  .contact {
    margin: 0 0 0 10%;
    width: 60%;
  }
  .qr {
    width: 20%;
    margin: 0 10% 0 0;
    padding: 30px 0 20px 0;
  }
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突，怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width:767px) {
  .footer {
    display: inline-block;
  }
  .contact {
    margin: 0 auto;
    width: 80%;
  }
  .qr {
    margin: 0 auto;
    width: 80%;
    padding: 0 0 20px 0;
  }
}
.footer {
  width: 100%;
  background: url(../assets/img/footer.png);
  background-size: cover;
}
.contact {
  // width: 80%;
  // margin: 0 auto;
  text-align: center;
  color: #ccc;
  padding: 20px 0 20px 0;
  font-size: 14px;
}
.contact h4 {
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.contact p {
  margin: 0;
}
.qr {
  // width: 80%;
  // margin: 0 auto;
  text-align: center;
  // padding: 0 0 20px 0;
}
.qr img {
  width: 120px;
}
</style>