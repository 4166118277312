<template>
  <div class="jobrequire">
    <div class="title">
      <img src="../../assets/img/join8.png" alt="">
    </div>
    <div v-for="(job, index) in item" :key="index" class="item">
      <div class="img">
        <img src="../../assets/img/join9.png" alt="">
        <p>{{ job.position }}</p>
      </div>
      <div class="itemtext">
        <div class="respon">
          <h4>{{ job.respon }}</h4>
          <p v-if="job.responoone != ''">{{ job.responoone }}</p>
          <p v-if="job.respontwo != '' ">{{ job.respontwo }}</p> 
          <p v-if="job.responthree != '' ">{{ job.responthree }}</p>
        </div>
        <div class="requirement">
          <h4>{{ job.requirement }}</h4>
          <p v-if="job.requirementone != '' ">{{ job.requirementone }}</p>
          <p v-if="job.requirementtwo != '' ">{{ job.requirementtwo }}</p>
          <p v-if="job.requirementthree != '' ">{{ job.requirementthree }}</p>
          <p v-if="job.requirementfour != '' ">{{ job.requirementfour }}</p>
          <p v-if="job.requirementfive != '' ">{{ job.requirementfive }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const item = require('./jobrequire.json')
    console.log(item)
    return {
      item,
    }
  },
})
</script>


<style lang="less" scoped>
/* 屏幕大于1200px显示的颜色 */
.jobrequire {
    margin: 0 auto;
    width: 80%;
  }
  .item {
    display: table;
    .img {
      padding-right: 40px;
      display: table-cell;
    }
  }

/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width: 1200px) {
  .jobrequire {
    margin: 0 auto;
    width: 80%;
  }
  .item {
    display: table;
    .img {
      padding-right: 40px;
      display: table-cell;
    }
  }
}
/* 小屏幕 768px-992px*/
@media screen and (max-width: 992px) {
  .jobrequire {
    margin: 0 auto;
    width: 80%;
  }
  .item {
    display: table;
    .img {
      padding-right: 40px;
      display: table-cell;
    }
  }
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突，怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width: 767px) {
  .jobrequire {
    // margin: 0 auto;
    width: 100%;
  }
  .item {
    display: block;
    .img {
      padding-right: 0;
      display: block;
    }
  }
}


.jobrequire {
  // margin: 0 auto;
  // width: 80%;
  padding: 120px 15px 60px 15px
}
.title {
  text-align: center;
  img {
    width: 100px;
  }
}
.item {
  padding-top: 42px;
  // display: table;
  margin: 0 auto;
  .img {
    // display: table-cell;
    vertical-align: middle;
    text-align: center;
    // padding-right: 40px;
  }
  .img img {
    width: 160px;
  }
  .img p {
    padding-top: 10px;
    font-size: 20px;
    font-weight: 500px;
    color: #0892db;
  }
  .itemtext {
    display: table-cell;
    vertical-align: middle;
    p {
      font-size: 14px;
      margin: 0;
    }
    .respon {
      border-bottom: 1px dashed #ccc;
      padding: 20px;
      h4 {
        font-weight: 500px;
        font-size: 16px;
      }
    }
    .requirement {
      padding: 20px;
    }
  }
}
</style>