<template>
  <div class="back">
    <div class="menu">
      <div class="left">
        <img class="logo" src="../assets/img/logo.png" alt="">
      </div>
      <div class="right">
        <div class="item">
          <ul>
            <li>
              <RouterLink to="/" class="lia">首页</RouterLink>
            </li>
            <li>
              <RouterLink to="/about" class="lia">关于我们</RouterLink>
            </li>
            <li>
              <div class="lia" href="">电子保函</div>
                <ul class="lialist">
                  <li>
                    <RouterLink to="/letterm" class="lia">保函管理</RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/letterh" class="lia">保函办理</RouterLink>
                  </li>
                </ul>
            </li>
            <li>
              <RouterLink to="/zhuzhu" class="lia">助筑通</RouterLink>
            </li>
            <li>
              <RouterLink to="/fast" class="lia">极速OA</RouterLink>
            </li>
            <li>
              <RouterLink to="/join" class="lia">联系我们</RouterLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="menubtn" :class="{active:act == false}" @click="show()">
        <div class="btnmicon">
          <span style="margin: 4px 1px 1px 10px; width:12px; border: solid 2px #FFFFFF;display:block;height:10px;border-radius: 3px;" class="span1"></span>
          <span style="margin: 4px 10px 1px 1px; width:12px; border: solid 2px #FFFFFF;display:block;height:10px;border-radius: 3px;" class="span1"></span>
        </div>
        <div class="btnmicon">
          <span style="margin: 1px 1px 4px 10px; width:12px; border: solid 2px #FFFFFF;display:block;height:10px;border-radius: 3px;" class="span1"></span>
          <span style="margin: 1px 10px 4px 1px; width:12px; border: solid 2px #FFFFFF;display:block;height:10px;border-radius: 3px;" class="span1"></span>
          
        </div>
      </div>
      <div class="iconright" :class="{active:act == false}" @click="show()">
        <div class="icon">
          <ul>
            <li><RouterLink to="/" class="lia">首页</RouterLink></li>
            <li><RouterLink to="/about" class="lia">关于我们</RouterLink></li>
            <li><RouterLink to="/letterm" class="lia">保函管理</RouterLink></li>
            <li><RouterLink to="/letterh" class="lia">保函办理</RouterLink></li>
            <li><RouterLink to="/zhuzhu" class="lia">助筑通</RouterLink></li>
            <li><RouterLink to="/fast" class="lia">极速OA</RouterLink></li>
            <li><RouterLink to="/join" class="lia">联系我们</RouterLink></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const act = ref(true)
    const show = () => {
      console.log(act.value)
      act.value = !act.value
      if (act.value) {
        act.value = true
      } else {
        act.value = false
      }
    }
   return {
    act,
    show,
   } 
  }
})
// export default {

// }
</script>

<style lang="less">
@keyframes fadeLeft{
  0% {
    opacity: 0;
    transform: translateY(0%);
    height: 0px;
  }
  100% {
    opacity: 1;
    transform: none;
    height: 35px;

  }
}

/* 屏幕大于1200px显示的颜色 */
.back { 
  background-color: #1f1a17;
}
.menu {
  display: block;
  padding: 0 15px 0 15px;
  width: 78%;
}
.menubtn {
  display: none;
}
.iconright {
  display: none;
}
.item li:hover {
  .lialist {
    padding: 8px;
  }
}
.right {
  width: 50%;
}
/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width:1200px) {
  .back {
    background-color: #1f1a17;
  }
  .menu {
    display: block;
    padding: 0 15px 0 15px;
    width: 78%;
  }
  .iconright {
    display: none;
  }
  .menubtn {
    display: none;
  }
  .item li:hover {
    .lialist {
      padding: 8px;
    }
  }
  .right {
    width: 55%;
  }
}
/* 小屏幕 768px-992px*/
@media screen and (max-width:992px) {
  .back {
    background-color: #1f1a17;
  }
  .menu {
    display: block;
    padding: 0 15px 0 15px;
    width: 82%;
  }
  .iconright {
    display: none;
  }
  .menubtn {
    display: none;
  }
  .item li:hover {
    .lialist {
      padding: 0px;
    }
  }
  .right {
    width: 67%;
  }
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突，怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width:767px) {
  .back {
    background-color: #1f1a17;
  }
  .menu {
    display: flex;
    padding: 0 5%;
    width: 100%;
    place-content: space-between;
  }
  .right {
    display: none;
  }
  .menubtn {
    display: block;
    width: 80px;
    // margin-right: 10%;
    // background-color: aqua;
  }
  .btnmicon {
    width: 100%;
    height: 15px;
    display: flex;
    justify-content: center;
  }
  .iconright {
    display: none;
  }
  .iconright.active {
    display: block;
    width: 100%;
    position: absolute;
    margin-top: 40px;
    // margin-right: 5%;
  }
  .icon {
    float: right;
    width: 80px;
    margin-right: 10%;
  }
  .iconright.active ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  // .iconright li {
    // padding: 0;
    // height: 0px;
    // transition: all 2s;
  // }
  .iconright.active li {
    background-color: #1f1a17;
    border-bottom: 1px solid #ccc;
    color: white;

    // visibility: visible;
    animation-name: fadeLeft;
    animation-duration: 0.8s;
    // animation-delay:1.2s;
    animation-fill-mode: both;
    // transition: all 2s;
    // transition-timing-function: ease;
    padding: 5px 0;
    // height: 35px;
  }
  .lia {
    color: white;
    opacity: 0.6;
    line-height: 24px;
  }
}
.back {
  width: 100%;
  margin: 0;
  padding: 10px 0;
  position: fixed;
  z-index: 999;
  // transition:  all 0.3s;
  // background: #1f1a17;
}
.menu {
  // display: flex;
  // width: 100%;
  // padding: 0 15px 0 15px;
  margin: 0 auto;
}
.left {
  float: left;
  width: 32%;
  .logo {
    // width: 100%;
    width: 100px;
  }
}
.right {
  float: right;
  // width: 67%;
  // list-style: none;
  color: white;
  .item ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .item li {
    width: 100%;
    border-radius: 20px;
    height: 32px;
    // .lihover {
    //   background-color: transparent;
    // }
    // .ahover {
    //   display: block;
    //   color: white;
    //   padding: 5px 5px 5px 5px;
    //   // background-color: #0093dd;
    //   border-radius: 20px;
    // }
    .lialist {
      display: none;
    }
    // padding: 10px 5px 10px 5px;
  }
  .item li:hover {
    cursor: pointer;
    border-radius: 20px;
    background-color: #0093dd;
    transition: 0.3s all;
    .lialist {
      display: block;
      // padding: 8px;
      background-color: #1f1a17;
      // li {
      //   padding: 5px 0;
      // }
    }
  }
  .item li a:hover {
    border-radius: 20px;
    background-color: #0093dd;
    transition: 0.3s all;
    .lialist {
      display: block;
    }
    .lihover {
      background-color: transparent;
    }
    .ahover {
      display: block;
      color: white;
      padding: 5px 5px 5px 5px;
      background-color: #0093dd;
      border-radius: 20px;
    }
    // transition: all 0.2s;
    // width: 100%;
    // padding: 10px 5px 10px 5px;
  }
  .lia {
    display: block;
    color: white;
    // line-height: 20px; 
    padding: 5px 5px 5px 5px;
  }
}
</style>