<template>
  <Header></Header>
  <div class="header">
    <img src="../assets/img/new/index/header.png" alt="">
  </div>
  <div :class="{carousel: true,carouseler: carouselshow}">
    <div class="title">
      <p>思创网联</p>
    </div>
    <p class="text">思创网联(北京)科技发展有限公司于2012年建立。致力于构建国内先进的电子保函数智化平台和相关应用软件，我们以专业的行业管理经验、权威的信息来源、优质的技术服务，为建设工程领域各方参与主体提供一站式的数字化解决方案。目前已服务客户20000余家，受到广大用户的一致好评!</p>
    <div class="arrow">
      <img style="width:40px" src="../assets/img/arrow.png" alt="">
    </div>
  </div>
  <div :class="{product: true,producter: productshow}">
    <div class="head">
      <img style="width:303px" src="../assets/img/new/index/product.png" alt="">
    </div>
    <div class="container">
      <ul>
        <li>
          <img src="../assets/img/new/index/icon1.png" alt="">
        </li>
        <li>
          <img src="../assets/img/new/index/icon2.png" alt="">
        </li>
        <li>
          <img src="../assets/img/new/index/icon3.png" alt="">
        </li>
        <li>
          <img src="../assets/img/new/index/icon4.png" alt="">
        </li>
      </ul>
    </div>
  </div>
  <div :class="{tender: true,tenderer: tendershow}" class="tender">
    <div class="title">
      <p style="margin-bottom:20px;">电子保函管、办一体化平台</p>
      <span style="font-size:16px">满足各行业管理需求、涉及全品类电子保函的专业化管理、电子化办理的一体化服务平台。</span>
    </div>
    <div class="row">
      <div class="img">
        <img src="../assets/img/new/index/product1.png" alt="">
      </div>
      <div class="content">
        <p>电子保函管、办一体化平台是思创网联公司深度研究我国工程担保行业管理现状与特点之后设计的适用于各个行业的管理系统软件，可结合电子保函的各种不同应用场景，综合运用行业领先的互联网技术，着力围绕提升管理效能、优化办理流程、提升管理透明度等目标，构建了一套功能完备、公开透明、安全可靠、弹性高效的软件系统。
        </p>
        <div class="button"><a href="#/letterm">查看详情</a></div>
      </div>
    </div>
  </div>
  <div style="background-color: #F5F5F5;" class="fast">
    <div :class="{title: true,titleer: titleshow}" class="title">
      <p style="margin-bottom:20px;">担保企业办公自动化平台</p>
      <span style="font-size:16px">通过现代化的技术方案，将企业线下的业务流程集中到信息化软件或信息化技术平台上。</span>
    </div>
    <div style="padding-bottom: 2%;" class="row">
      <div :class="{img: true,imger: imgshow}" class="img">
        <img src="../assets/img/new/index/fast.png" alt="">
      </div>
      <div :class="{content: true,contenter: contentshow}" class="content">
        <p>担保企业办公自动化平台通过现代通信、网络、数据库等新一代技术方案，将企业的业务流程、经营管理、品牌运营以及营销推广等线下的业务流程集中到信息化软件或信息化技术平台上，实现无纸化、网络化、自动化的办公、业务处理和数据存储机制。
        </p>
        <div class="button"><a href="#/fast">查看详情</a></div>
      </div>
    </div>
    <div :class="{advantage: true,advantageer: advantageshow}" class="advantage">
      <div class="item">
        <div class="itemo">
          <div class="itemotitle">量身打造</div>
          <div class="itemoimg">
            <img src="../assets/img/new/fast/fast-icon1.png" alt="">
          </div>
          <div class="itemoimgt">
            <img src="../assets/img/new/index/iconbg.png" alt="">
          </div>
          <div class="itemotext">
            <!-- <p>担保业务全覆盖、整合保函业务流程</p>
            <p>电子保函、电子合同</p>
            <p>支持审理会、董事会</p>
            <p>商业分析（BI）功能、业务报表</p> -->
          </div>
        </div>
        <div class="itemo">
          <div class="itemotitle">成熟可靠</div>
          <div class="itemoimg">
            <img src="../assets/img/new/fast/fast-icon2.png" alt="">
          </div>
          <div class="itemoimgt">
            <img src="../assets/img/new/index/iconbg.png" alt="">
          </div>
          <div class="itemotext">
            <!-- <p>经过十余家行业头部企业成功应用</p>
            <p>已形成一整套工作流程</p>
            <p></p>
            <p></p> -->
          </div>
        </div>
        <div class="itemo">
          <div class="itemotitle">高效互联</div>
          <div class="itemoimg">
            <img src="../assets/img/new/fast/fast-icon3.png" alt="">
          </div>
          <div class="itemoimgt">
            <img src="../assets/img/new/index/iconbg.png" alt="">
          </div>
          <div class="itemotext">
            <!-- <p>线上收单/办理</p>
            <p>行业管理系统</p>
            <p>联保分保系统</p> -->
            <p></p>
          </div>
        </div>
        <div class="itemo">
          <div class="itemotitle">多端部署</div>
          <div class="itemoimg">
            <img src="../assets/img/new/fast/fast-icon4.png" alt="">
          </div>
          <div class="itemoimgt">
            <img src="../assets/img/new/index/iconbg.png" alt="">
          </div>
          <div class="itemotext">
            <!-- <p>Web</p>
            <p>小程序</p>
            <p>消息推送</p>
            <p>APP（安卓、iOS）</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="zhuzhu">
    <div :class="{zhuzhutitle: true,zhuzhutitleer: zhuzhutitleshow}" class="zhuzhutitle">
      <p style="margin-bottom:20px;">农民工工资支付风险预警管理平台</p>
      <span style="font-size:16px">农民工工资支付风险预警平台、农民工工资支付过程管理系统!</span>
    </div>
    <div class="row">
      <div :class="{zhuzhucontent: true,zhuzhucontenter: zhuzhucontentshow}" class="zhuzhucontent">
        <p>农民工工资支付风险预警管理平台是思创网联(北京)科技发展有限公司针对农民工工资支付管理中容易出现各类痛点和问题自主研发的一套解决农民工工资支付风险事中监控、风险预警和提供欠薪、恶意讨薪处置依据的可视化管理平台。
        </p>
        <div class="button"><a href="#/zhuzhu">查看详情</a></div>
      </div>
      <div class="img">
        <div :class="{imgleft: true,imglefter: imgleftshow}" class="imgleft">
          <div class="icon">
            <img src="../assets/img/new/index/icon5.png" alt="">
            <p>多种数据来源</p>
          </div>
          <div style="padding-top:40%;margin-left:15%;" class="icon">
            <img src="../assets/img/new/index/icon7.png" alt="">
            <p>事中监控响应</p>
          </div>
        </div>
        <div class="imgmi">
          <img src="../assets/img/new/index/zhuzhu.png" alt="">
        </div>
        <div :class="{imgright: true,imgrighter: imgrightshow}" class="imgright">
          <div class="icon">
            <img src="../assets/img/new/index/icon6.png" alt="">
            <p>提供处置建议</p>
          </div>
          <div class="icon" style="padding-top:40%;margin-left:-12%;">
            <img src="../assets/img/new/index/icon8.png" alt="">
            <p>风险提前预警</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div :class="{letterh: true,letterher: letterhshow}" style="background-color: #F5F5F5; padding-bottom: 40px" class="letterh">
    <div class="title">
      <p style="margin-bottom:20px;">思创电子投标保函管理平台</p>
      <span style="font-size:16px">深度聚焦招投标领域的投标保函办理、管理、监管、一体化服务平台。</span>
    </div>
    <div :class="{characteristic: true,characteristicer: characteristicshow}" class="characteristic">
    <div class="main">
      <div class="characteristicrow">
        <div class="characteristicrowcon">
          <img src="../assets/img/new/letterh/letterh-icon1.png" alt="">
          <p>唯一性</p>
        </div>
        <div class="characteristicrowcon">
          <img src="../assets/img/new/letterh/letterh-icon2.png" alt="">
          <p>真实性</p>
        </div>
        <div class="characteristicrowcon">
          <img src="../assets/img/new/letterh/letterh-icon3.png" alt="">
          <p>保密性</p>
        </div>
        <div class="characteristicrowcon con">
          <img src="../assets/img/new/letterh/letterh-icon4.png" alt="">
          <p>可追溯</p>
        </div>
        <div class="characteristicrowcon">
          <img src="../assets/img/new/letterh/letterh-icon5.png" alt="">
          <p>易理赔</p>
        </div>
      </div>
    </div>
  </div>
    <div class="row">
      <div :class="{letterhimg: true,letterhimger: letterhimgshow}" class="letterhimg">
        <img src="../assets/img/new/index/product1.png" alt="">
      </div>
      <div :class="{letterhcontent: true,letterhcontenter: letterhcontentshow}" class="letterhcontent">
        <p>思创电子投标保函管理平台为政府部门提供了有力地监管手段，并对现有的电子投标保函市场进行有效的规范，提高监管部门的工作效率。思创电子投标保函管理平台确保入驻的担保机构合法合规，接受主管部门的监管，从而确保投保人和招标人的权益有保障。从平台出具的保函具备唯一性、真实性、保密性、可追溯、易理赔等特性。平台同时提供查询、验真服务。
        </p>
        <div class="button"><a href="#/letterh">查看详情</a></div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
  components: {
    Header,
    // Banner,
    Footer
  },
  setup() {
    const currentScroll = ref(0)
    onMounted(()=>{
      window.addEventListener('scroll', handleScroll)
      currentScroll.value = 0
    })
    // const currentScroll = ref('')
    const carouselshow = ref(true)
    const productshow = ref(false)
    const tendershow = ref(false)
    const titleshow = ref(false)
    const imgshow = ref(false)
    const contentshow = ref(false)
    const advantageshow = ref(false)
    const zhuzhutitleshow = ref(false)
    const zhuzhucontentshow = ref(false)
    const imgleftshow = ref(false)
    const imgrightshow = ref(false)
    const letterhshow = ref(false)
    const characteristicshow = ref(false)
    const letterhimgshow = ref(false)
    const letterhcontentshow = ref(false)
    const handleScroll = () => {
      currentScroll.value = window.pageYOffset
      if (currentScroll.value >= 100) {
        productshow.value = true
      }
      if (currentScroll.value >= 500) {
        tendershow.value = true
      }
      if (currentScroll.value >= 1300) {
        titleshow.value = true
      }
      if (currentScroll.value >= 1500) {
        imgshow.value = true
        contentshow.value = true
      }
      if (currentScroll.value >= 1900) {
        advantageshow.value = true
      }
      if (currentScroll.value >= 2400) {
        zhuzhutitleshow.value = true
      }
      if (currentScroll.value >= 2600) {
        zhuzhucontentshow.value = true
      }
      if (currentScroll.value >= 2900) {
        imgleftshow.value = true
        imgrightshow.value = true
      }
      if (currentScroll.value >= 3300) {
        letterhshow.value = true
      }
      if (currentScroll.value >= 3500) {
        characteristicshow.value = true
      }
      if (currentScroll.value >= 3700) {
        letterhimgshow.value = true
        letterhcontentshow.value = true
      }
    }

    return {
      carouselshow,
      productshow,
      tendershow,
      titleshow,
      imgshow,
      contentshow,
      advantageshow,
      zhuzhutitleshow,
      zhuzhucontentshow,
      imgleftshow,
      imgrightshow,
      letterhshow,
      characteristicshow,
      letterhimgshow,
      letterhcontentshow,
    };
  },

});
</script>

<style lang="less" scoped>
@import url('../../static/less/less.css');
/* 屏幕大于1200px显示的颜色 */
.product {
  .container li {
    float: left;
    width: 25%;
  }
}
.tender {
  .row {
    display: flex;
    padding-top: 2%;
  }
}
.fast {
  .row {
    display: flex;
    padding-top: 2%;
  }
}
.letterh {
  .row {
    display: flex;
    padding-top: 5%;
    padding-bottom: 5%;
  }
}
.characteristicrow {
  display: flex;
  width: 80%;
  margin: 0 auto;
  .characteristicrowcon {
    width: 20%;
  }
  // .characteristicrowcon img {
  //   width: 70%;
  // }
  .con {
    margin-left: 0;
  }
}
.zhu {
  .zhulist ul {
    margin: 0 5%;
  }
  .zhulist li {
    width: 15%;
  }
  .zhulist p {
    padding-bottom: 53px;
  }
}

/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width:1200px) {
  .product {
    .container li {
      float: left;
      width: 25%;
    }
  }
  .tender {
    .row {
      display: flex;
    }
  }
  .fast {
    .row {
      display: flex;
    }
  }
  .letterh {
    .row {
      display: flex;
    }
  }
  .characteristicrowconrow {
    display: flex;
    width: 80%;
    margin: 0 auto;
    .characteristicrowconrowcon {
      width: 20%;
    }
    // .characteristicrowconrowcon img {
    //   width: 80%;
    // }
    .con {
      margin-left: 0;
    }
  }
  .zhu {
    .zhulist ul {
      margin: 0 5%;
    }
    .zhulist li {
      width: 15%;
    }
    .zhulist p {
      padding-bottom: 53px;
    }
  }
}
/* 小屏幕 768px-992px*/
@media screen and (max-width:992px) {
  .product {
    .container li {
      width: 98%;
    }
  }
  .tender {
    .row {
      display: block;
    }
  }
  .fast {
    .row {
      display: block;
    }
  }
  .letterh {
    .row {
      display: block;
    }
  }
  .characteristicrowconrow {
    display: inline-block;
    width: 100%;
    .characteristicrowconrowcon {
      width: 33%;
      float: left;
    }
    .con {
      margin-left: 16.5%;
    }
  }
  .zhu {
    .zhulist ul {
      margin: 0;
    }
    .zhulist li {
      width: 33%;
    }
    .zhulist p {
      padding-bottom: 10px;
    }
  }
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突，怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width:767px) {
  .product {
    .container li {
      width: 98%;
    }
  }
  .carousel .text {
    width: 100% !important;
  }
  .product .head {
    width: 100% !important;
  }
  .tender {
    .row {
      display: block;
      .img {
        margin-left: 0%;
        text-align: center;
      }
      .img img {
        width: 100%;
      }
    }
    .list li {
      width: 25%;
      margin: 10px 0 0 0;
      padding: 0 7px;
    }
  }
  .fast {
    .row {
      display: block;
      .img {
        margin-left: 0%;
        text-align: center;
      }
      .img img {
        width: 100%;
      }
    }
    .advantage .item .itemo .itemoimg img {
      width: 80% !important;
    }
    .advantage {
      margin-top: 12% !important;
      .item {
        flex-wrap: wrap;
      }
      .itemo {
        padding-top: 22px !important;
        margin-top: 2px !important;
        width: 48% !important;
      }
    }
  }
  .zhuzhu .row .zhuzhucontent {
    width: 100% !important;
  }
  .zhuzhu .row .img .imgmi {
    display: inline-flex !important;
    align-items: center !important;
    img {
      width: 100%;
    }
  }
  .zhuzhu .row .img .imgleft .icon {
    text-align: center;
  }
  .zhuzhu .row .img .imgright .icon {
    text-align: center;
  }
  .letterh {
    .row {
      display: block;
      .letterhimg {
        margin-left: 0%;
        text-align: center;
      }
      .letterhimg img {
        width: 100%;
      }
    }
  }
  .characteristicrow {
    display: inline-block;
    width: 100%;
    .characteristicrowcon {
      width: 33%;
      float: left;
      padding-top: 20px;
    }
    .con {
      margin-left: 16.5%;
    }
  }
  .zhu {
    .zhulist ul {
      margin: 0;
      height: 330px;
    }
    .zhulist li {
      width: 33%;
    }
    .zhulist p {
      padding-bottom: 10px;
    }
  }
}
// 公共css
p {
  color: #000000;
  margin: 0;
}
.titleer {
  position: relative;
  animation-name: FadeUp;
  animation-duration: 1.5s;
  opacity: 1 !important;
}
.title {
  width: 75%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}
.title p {
  padding: 60px 0 0 0 ;
  font-size: 32px;
  color: #2161BF;
  font-weight: 800;
}
.arrow {
  text-align: center;
  padding-top: 40px;
}
// 小块
.header {
  padding: 50px 0 0 0;
}
.header img {
  width: 100%;
}
.carouseler {
  position: relative;
  animation-name: FadeUp;
  animation-duration: 1.5s;
  opacity: 1 !important;
}
.carousel {
  opacity: 0;
  padding: 0 20px;
  margin: 0 auto;
  width: 80%;
  .text {
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    width: 80%;
    margin: 0 auto;
  }
}
.producter {
  position: relative;
  animation-name: FadeDown;
  animation-duration: 1.5s;
  opacity: 1 !important;
}
.product {
  opacity: 0;
  padding-top: 108px;
  .head {
    text-align: center;
    padding-bottom: 40px;
    width: 75%;
    margin: 0 auto;
  }
  .container {
    margin: 0 auto;
    width: 75%;
  }
  .container ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    text-align: center;
    width: 100%;
    overflow: hidden;
  }
  .container li {
    padding: 6% 10px 6% 10px;
    text-align: center;
    p {
      padding-top: 36px;
      color: #000000;
    }
  }
}
.tenderer {
  position: relative;
  animation-name: FadeDown;
  animation-duration: 2s;
  opacity: 1 !important;
}
.tender {
  opacity: 0;
  margin-top: 5%;
  margin-bottom: 10%;
  .row {
    margin: 0 auto;
    width: 75%;
    .content {
      color: #000000;
      margin: 0 5% 0 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .button {
        width: 173px;
        height: 40px;
      }
      .button a {
        text-decoration: none;
        display: block;
        background: #3D95FF;
        margin-top: 32px;
        line-height: 40px;
        border-radius: 5px;
        text-align: center;
        color: #FFFFFF;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .content h4 {
      font-size: 20px;
      font-weight: 600;
    }
    .content p {
      font-size: 14px;
      margin: 0;
      line-height: 32px;
    }
  }
  .list {
    margin: 0 auto;
    padding: 10px 20px;
    width: 80%;
    padding-bottom: 106px;
  }
  .list ul {
    margin: 0 5%;
    padding: 0;
    list-style: none;
  }
  .list li {
    text-align: center;
    float: right;
  }
}
.conntt {
  width: 100%;
}
.advantageer {
  position: relative;
  animation-name: FadeDown;
  animation-duration: 2s;
  opacity: 1 !important;
}
.advantage {
  opacity: 0;
  width: 75%;
  margin: 0 auto;
  padding-top: 20px;
  .item {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    .itemo:hover {
      background: url(../assets/img/new/index/iconbgbg.png);
      background-size: cover;
      .itemotitle {
        color: #FFFFFF;
      }
    }
    .itemo {
      padding: 42px 0 0 0;
      width: 20%;
      background-color: #FFFFFF;
      box-shadow: 0px 2px 6px #bfbfbf;
      border-radius: 12px;
      .itemotitle {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
      .itemoimg {
        padding: 20px 0 0 0;
        width: 100%;
        text-align: center;
      }
      .itemoimgt {
        width: 100%;
        text-align: right;
        img {
          margin-top: -40px;
          width: 100%;
        }
      }
      .itemotext {
        padding: 0 6%;
        text-align: center;
        line-height: 2;
        p {
          margin: 0;
        }
      }
    }
  }
}
.fast {
  margin-top: 5%;
  padding-bottom: 10%;
  .titleer {
    position: relative;
    animation-name: FadeUp;
    animation-duration: 1.5s;
    opacity: 1 !important;
  }
  .title {
    opacity: 0;
    width: 75%;
    margin: 0 auto;
    margin-bottom: 60px;
    text-align: center;
  }
  .title p {
    padding: 60px 0 0 0 ;
    font-size: 32px;
    color: #2161BF;
    font-weight: 800;
  }
  .row {
    margin: 0 auto;
    width: 75%;

    .imger {
      position: relative;
      animation-name: fadeLeft;
      animation-duration: 1.5s;
      opacity: 1 !important;
    }
    .img {
      opacity: 0;
    }
    .contenter {
      position: relative;
      animation-name: fadeRight;
      animation-duration: 1.5s;
      opacity: 1 !important;
    }
    .content {
      opacity: 0;
      color: #000000;
      margin: 0 5% 0 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .button {
        width: 173px;
        height: 40px;
      }
      .button a {
        text-decoration: none;
        display: block;
        background: #3D95FF;
        margin-top: 32px;
        line-height: 40px;
        border-radius: 5px;
        text-align: center;
        color: #FFFFFF;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .content h4 {
      font-size: 20px;
      font-weight: 600;
    }
    .content p {
      font-size: 14px;
      margin: 0;
      line-height: 32px;
    }
  }
  .list {
    margin: 0 auto;
    padding: 10px 20px;
    width: 80%;
    padding-bottom: 106px;
  }
  .list ul {
    margin: 0 5%;
    padding: 0;
    list-style: none;
  }
  .list li {
    text-align: center;
    float: right;
  }
}
.zhuzhu {
  background: url(../assets/img/new/index/zhuzhubg.png);
  background-size: cover;
  padding-top: 5%;
  .zhuzhutitleer {
    position: relative;
    animation-name: FadeDown;
    animation-duration: 1.5s;
    opacity: 1 !important;
  }
  .zhuzhutitle {
    opacity: 0;
    width: 75%;
    margin: 0 auto;
    margin-bottom: 60px;
    text-align: center;
  }
  .zhuzhutitle p {
    padding: 60px 0 0 0 ;
    font-size: 32px;
    color: #2161BF;
    font-weight: 800;
  }
  .row {
    margin: 0 auto;
    width: 75%;
    .zhuzhucontenter {
      position: relative;
      animation-name: FadeUp;
      animation-duration: 1.5s;
      opacity: 1 !important;
    }
    .zhuzhucontent {
      opacity: 0;
      color: #000000;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      width: 60%;
      align-items: center;
      .button {
        width: 173px;
        height: 40px;
        margin-top: 32px;
      }
      .button a {
        text-decoration: none;
        display: block;
        background: #3D95FF;
        line-height: 40px;
        border-radius: 5px;
        text-align: center;
        color: #FFFFFF;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .zhuzhucontent h4 {
      font-size: 20px;
      font-weight: 600;
    }
    .zhuzhucontent p {
      font-size: 14px;
      margin: 0;
      line-height: 32px;
      text-align: center;
    }
    .img {
      display: flex;
      width: 100%;
      padding: 40px 0 40px 0;
      .imglefter {
        position: relative;
        animation-name: fadeLeft;
        animation-duration: 2s;
        opacity: 1 !important;
      }
      .imgleft {
        opacity: 0;
        width: 25%;
        text-align: right;
        .icon {
          width: 100%;
        }
      }
      .imgmi {
        width: 50%;
        margin: 2% 0;
        img {
          width: 90%;
        }
      }
      .imgrighter {
        position: relative;
        animation-name: fadeRight;
        animation-duration: 2s;
        opacity: 1 !important;
      }
      .imgright {
        opacity: 0;
        width: 25%;
      }
    }
  }
  .list {
    margin: 0 auto;
    padding: 10px 20px;
    width: 80%;
    padding-bottom: 106px;
  }
  .list ul {
    margin: 0 5%;
    padding: 0;
    list-style: none;
  }
  .list li {
    text-align: center;
    float: right;
  }
}
.letterher {
  position: relative;
  animation-name: FadeDown;
  animation-duration: 2s;
  opacity: 1 !important;
}
.letterh {
  opacity: 0;
  .characteristicer {
    position: relative;
    animation-name: FadeDown;
    animation-duration: 1.5s;
    opacity: 1 !important;
  }
  .characteristic {
    opacity: 0;
  width: 75%;
  margin: 0 auto;
  padding: 0 15px;
  .main {
    padding-top: 24px;
    font-size: 14px;
    text-align: center;
    .maintext {
      color: #787878;
    }
    .characteristicrow {
      .characteristicrowcon p {
        color: #2161BF;
        font-weight: 600;
        padding-top: 10px;
        font-size: 16px;
      }
    }
  }
  }
  .row {
    margin: 0 auto;
    width: 75%;
    .letterhimger {
      position: relative;
      animation-name: fadeRight;
      animation-duration: 1.5s;
      opacity: 1 !important;
    }
    .letterhimg {
      opacity: 0;
    }
    .letterhcontenter {
      position: relative;
      animation-name: fadeLeft;
      animation-duration: 1.5s;
      opacity: 1 !important;
    }
    .letterhcontent {
      opacity: 0;
      color: #000000;
      margin: 0 5% 0 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .button {
        width: 173px;
        height: 40px;
      }
      .button a {
        text-decoration: none;
        display: block;
        background: #3D95FF;
        margin-top: 32px;
        line-height: 40px;
        border-radius: 5px;
        text-align: center;
        color: #FFFFFF;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .letterhcontent h4 {
      font-size: 20px;
      font-weight: 600;
    }
    .letterhcontent p {
      font-size: 14px;
      margin: 0;
      line-height: 32px;
      text-align: justify;
    }
  }
}



.worker {
  background: url(../assets/img/worker.jpg) center center no-repeat;
  background-size: cover;
  margin-top: 106px;
  // margin: 0 auto;
  // padding: 0 20px;
  // width: 80%;
  .workertext {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding-top: 86px;
  }
  .workertext h4 {
    padding-bottom: 44px;
    font-size: 20px;
    color: #00a88e;
  }
  .workertext p {
    color: white;
    margin: 0;
  }
  .workerimg {
    width: 80%;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
  }
  .workerimg img {
    width: 80%;
  }
}
.zhu {
  margin-top: 40px;
  .zhucenttext {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding-top: 86px;
  }
  .zhucenttext h4 {
    padding-bottom: 28px;
    font-size: 20px;
    font-weight: 600;
    // color: #00a88e;
  }
  .zhucenttext p {
    color: #333;
    margin: 0;
  }
  .zhucentimg {
    width: 80%;
    margin: 0 auto;
    padding-top: 54px;
    padding-bottom: 40px;
    text-align: center;
  }
  .zhucentimg img {
    width: 80%;
  }
  .zhulist {
    width: 80%;
    margin: 0 auto;
  }
  .zhulist ul {
    padding: 0;
    list-style: none;
    width: 100%;
  }
  .zhulist li {
    text-align: center;
    // width: 15%;
    color: #000000;
    padding-bottom: 20px;
    float: left;
  }
  .zhulist img {
    width: 40%;
  }
  .zhulist h6 {
    font-size: 14px;
    margin: 10px 0 10px 0;
    font-weight: 500;
    line-height: 1.1;
  }
  .zhulist p {
    font-size: 12px;
    margin: 0;
    // padding-bottom: 53px;
  }
}
</style>