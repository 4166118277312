<template>
  <Header></Header>
  <!-- <banner></banner> -->
  <div class="header">
    <img src="../assets/img/join1.jpg" alt="">
  </div>
  <div class="xinzi">
    <div class="title">
      <img src="../assets/img/join2.png" alt="">
    </div>
    <div class="main">
      <div class="maintext">
        我们提供有竞争力的薪资待遇及完善丰富的福利体系，让每一位员工为理想奋斗的同时没有后顾之忧。
      </div>
      <div class="row">
        <div class="rowcon">
          <img src="../assets/img/join3.png" alt="">
          <p>社会保险</p>
        </div>
        <div class="rowcon">
          <img src="../assets/img/join4.png" alt="">
          <p>带薪休假</p>
        </div>
        <div class="rowcon">
          <img src="../assets/img/join5.png" alt="">
          <p>周末双休</p>
        </div>
        <div class="rowcon con">
          <img src="../assets/img/join6.png" alt="">
          <p>交通补贴</p>
        </div>
        <div class="rowcon">
          <img src="../assets/img/join7.png" alt="">
          <p>奖金</p>
        </div>
      </div>
    </div>
  </div>
  <jobrequire></jobrequire>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/header.vue";
import jobrequire from './join/jobrequire.vue';
import Footer from "@/components/footer.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    Header,
    jobrequire,
    Footer,
  },

  setup() {
    return {};
  },
});
</script>

<style lang="less" scoped>
/* 屏幕大于1200px显示的颜色 */
.row {
  display: flex;
  width: 80%;
  margin: 0 auto;
  .rowcon {
    width: 20%;
  }
  // .rowcon img {
  //   width: 70%;
  // }
  .con {
    margin-left: 0;
  }
}

/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width: 1200px) {
  .row {
    display: flex;
    width: 80%;
    margin: 0 auto;
    .rowcon {
      width: 20%;
    }
    .rowcon img {
      width: 80%;
    }
    .con {
      margin-left: 0;
    }
  }
}
/* 小屏幕 768px-992px*/
@media screen and (max-width: 992px) {
  .row {
    display: inline-block;
    width: 100%;
    .rowcon {
      width: 33%;
      float: left;
    }
    .con {
      margin-left: 16.5%;
    }
  }
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突，怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width: 767px) {
  .row {
    display: inline-block;
    width: 100%;
    .rowcon {
      width: 33%;
      float: left;
    }
    .con {
      margin-left: 16.5%;
    }
  }
}
// 公共css
.arrow {
}
// 小块
.header {
  padding: 50px 0 0 0;
}
.header img {
  width: 100%;
}
.xinzi {
  width: 80%;
  margin: 0 auto;
  padding: 0 15px;
  .title {
    padding-top: 90px;
    text-align: center;
  }
  .title img {
    width: 200px;
  }
  .main {
    padding-top: 24px;
    font-size: 14px;
    text-align: center;
    .maintext {
      // text-align: center;
      color: #787878;
    }
    .row {
      padding-top: 70px;
      // display: flex;
      // .rowcon {
      //   width: 20%;
      // }
      // .rowcon img {
      //   width: 80%;
      // }
      .rowcon p {
        padding-top: 10px;
        font-size: 16px;
        color: #2161BF;
        font-weight: 800;
      }
    }
  }
}
</style>