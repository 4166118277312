<template>
  <Header></Header>
  <!-- <banner></banner> -->
  <div class="header">
    <img src="../assets/img/new/zhuzhu/zhuzhu-header.png" alt="">
  </div>
  <div class="conntt">
    <div class="feature">
      <div class="title">
        <p>产品特点</p>
      </div>
      <div class="item">
        <div class="itemo">
          <div class="itemoimg">多种数据来源</div>
          <p class="itemotext">
            通过门禁设备、微信小程序、施工企业的应用端口等多个对象来实现数据的采集。
          </p>
        </div>
        <div class="itemt">
          <div class="itemoimg">事中监控响应</div>
          <p class="itemotext">
            以实名制为基础，互联网为工具，将数据实时进行收集传递，从而达到对施工过程的动态监控。
          </p>
        </div>
        <div class="itemth">
          <div class="itemoimg">风险提前预警</div>
          <p class="itemotext">
            根据采集的数据，结合工程担保行业风险控制标准及规范进行数据分析，输出可视化预警信息。
          </p>
        </div>
        <div class="itemf">
          <div class="itemoimg">提供处置意见</div>
          <p class="itemotext">
            基于预警信息，按照对象及发生场景类型分别提供不同的处置建议。
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="ad">
    <div class="adimg">
      <img src="../assets/img/new/zhuzhu/zhuzhu-ad.png" alt="">
    </div>
    <p class="adtext">
      农民工工资支付风险预警管理平台是思创网联(北京)科技发展有限公司针对农民工工资支付管理中容易出现各类痛点和问题自主研发的一套解决农民工工资支付风险事中监控、风险预警和提供欠薪、恶意讨薪处置依据的可视化管理平台。
    </p>
  </div>
  <div class="function">
    <div class="title">
      <p style="color: #F0F0F0">平台功能</p>
    </div>
    <div class="funitem">
      <div class="funitemo">
        <span>辖内项目项目概览查看</span>
        <p>包含辖内所有项目的分布，项目的状态，项目进度等关键数据。</p>
      </div>
      <div class="funitemo">
        <span>项目进度信息查看</span>
        <p>辖内各个项目的进度信息记录，数据每月进行更新。</p>
      </div>
      <div class="funitemo">
        <span>辖内项目风险指数查看</span>
        <p>
          通过风控预警模型对采集数据进行分析后，得到各个项目的风险指数，并直接展示在可视化平台项目状态中，同时能够查看项目风险指数分析报告。
        </p>
      </div>
      <div class="funitemo">
        <span>辖内项目维权信息查看</span>
        <p>辖内农民工对项目的维权信息的信息反馈，能够提供维权人信息、联系方式、维权内容、涉及的项目施工企业和班组、维权欠薪金额。</p>
      </div>
      <div class="funitemo">
        <span>项目信息详情查看</span>
        <p>辖内各个项目的参与各方的企业信息、项目施工进度、项目人员信息、薪资发放记录。</p>
      </div>
      <div class="funitemo">
        <span>辖内信息发布</span>
        <p>能够对辖内所有项目的人员进行信息发布，信息发布对象可以是施工企业、业主方、施工人员。</p>
      </div>
      <div class="funitemo">
        <span>项目人员动态查看</span>
        <p>辖内各个项目的人员出勤上工记录，包含对出勤信息的日分析、周分析和月分析。</p>
      </div>
    </div>
  </div>
  <div class="type">
    <div class="title">
      <p>应用类型</p>
    </div>
    <div class="typebg">
      <div class="typeon">
        <div class="typetext">
          <h4>企业级应用</h4>
          <p>
            农民工工资支付风险预警管理平台的数据来源不仅来自于上述物联网硬件设备，针对担保业务公司推出助筑通企业应用，在合法合规的条件下，将助筑通企业应用产生的有效数据进行汇总分析，传递至风险预警管理平台。
          </p>
        </div>
        <div class="typeimg">
          <img src="../assets/img/new/zhuzhu/zhuzhu-type1.png" alt="">
        </div>
      </div>
      <div class="typeto">
        <div class="typetoimg">
          <img src="../assets/img/new/zhuzhu/zhuzhu-type2.png" alt="">
        </div>
        <div class="typetocon">
          <h4>移动应用</h4>
          <div class="typetotext">
            <span>地区围栏签到:</span>
            <p>基于LBS地理位置坐标的项目打卡签到功能,轻量化,便捷易实施。</p>
          </div>
          <div class="typetotext">
            <span>工人自主注册:</span>
            <p>工人自主注册并实名认证，自主加入项目。</p>
          </div>
          <div class="typetotext">
            <span>匿名投诉维权:</span>
            <p>
              为农民工维权提供了一条快捷的通道，并结合风控模型，维权信息会进行快速响应，对上报的事件进行及时的核实。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="background-color: #F5F5F5;width:100%">
    <div class="dp">
      <div class="dptext">
        <h2>可视化大屏幕</h2>
        <p>
          农民工工资支付风险预警管理平台以可视化大屏幕的形式，通过在项目部署的物联设备(门禁设备、摄像设备)能够实时的获取到管辖范围内项目的人员进出记录，同时，基于风控预警模型，云端服务能够将设备获取的数据分析计算，从而得到项目的风险指数。
        </p>
      </div>
      <div class="dpimg">
        <img src="../assets/img/new/zhuzhu/zhuzhu-dp.png" alt="">
      </div>
    </div>
  </div>
  <!-- <div class="culture">
    <div class="culturetext">
      <p>让建设领域借助大数据、物联网等信息化工具更简单、高效！为推进中国建筑业进入信息化时代贡献力量！</p>
    </div>
  </div> -->
  <Footer></Footer>
</template>

<script>
import Header from "@/components/header.vue";
// import Banner from "@/components/banner.vue";
import Footer from "@/components/footer.vue";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  components: {
    Header,
    // Banner,
    Footer,
  },

  setup() {
    onMounted(() => {
      _loadNewPage()
    })
    const _loadNewPage = () => {
       window.scrollTo(0, 0); // 滚动条回到页面顶端(x坐标, y坐标)
    }
    return {
      _loadNewPage,
    }
  },
});
</script>

<style lang="less" scoped>
/* 屏幕大于1200px显示的颜色 */

/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width: 1200px) {

}
/* 小屏幕 768px-992px*/
@media screen and (max-width: 992px) {
  
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突,怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width: 767px) {
  .item {
    display: block !important;
    .itemo {
      width: 100% !important;
    }
    .itemt {
      width: 100% !important;
    }
    .itemth {
      width: 100% !important;
    }
    .itemf {
      width: 100% !important;
    }
  }
  .ad {
    display: block !important;
    .adimg {
      width: 100% !important;
    }
    .adtext {
      width: 100% !important;
    }
  }
}
// 公共css
h4 {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  padding-bottom: 5%;
}
p {
  color: #000000;
}
.title {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}
.title p {
  padding: 60px 0 0 0 ;
  font-size: 32px;
  color: #2161BF;
  font-weight: 800;
}
// 小块
.header {
  padding: 50px 0 0 0;
}
.header img {
  width: 100%;
}
.conntt {
  width: 100%;
}
.feature {
  width: 75%;
  margin: 0 auto;
  .item {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    .itemo {
      // display: flex;
      box-shadow: 0px 2px 6px #bfbfbf;
      border-radius: 12px;
      width: 23%;
      .itemoimg {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        background: url(../assets/img/new/zhuzhu/zhuzhu-icon1.png);
        background-size: cover;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .itemotext {
        padding: 12%;
      }
    }
    .itemt {
      box-shadow: 0px 2px 6px #bfbfbf;
      border-radius: 12px;
      // display: flex;
      width: 23%;
      .itemoimg {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        background: url(../assets/img/new/zhuzhu/zhuzhu-icon2.png);
        background-size: cover;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .itemotext {
        padding: 12%;
      }
    }
    .itemth {
      box-shadow: 0px 2px 6px #bfbfbf;
      border-radius: 12px;
      // display: flex;
      width: 23%;
      .itemoimg {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        background: url(../assets/img/new/zhuzhu/zhuzhu-icon3.png);
        background-size: cover;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .itemotext {
        padding: 12%;
      }
    }
    .itemf {
      box-shadow: 0px 2px 6px #bfbfbf;
      border-radius: 12px;
      // display: flex;
      width: 23%;
      .itemoimg {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        background: url(../assets/img/new/zhuzhu/zhuzhu-icon4.png);
        background-size: cover;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .itemotext {
        padding: 12%;
      }
    }

  }
}
.ad {
  padding: 80px 0 0 0;
  display: flex;
  width: 75%;
  margin: 0 auto;
  .adimg {
    padding: 2%;
    width: 50%;
    img {
      width: 100%;
    }
  }
  .adtext {
    padding: 3%;
    width: 50%;
    display: flex;
    align-items: center;
  }
}
.function {
  background: url(../assets/img/new/zhuzhu/zhuzhu-function.png);
  background-size: cover;
  .funitem {
    width: 75%;
    margin: 0 auto;
    padding-bottom: 80px;
    // display: flex;
    .funitemo {
      background-color: rgba(255,255,255,0.2);
      width: 46%;
      margin: 2%;
      padding: 28px;
      border-radius: 12px;
      display: inline-block;
      span {
        color: #FFFFFF;
        font-size: 15px;
        font-weight: 600;
      }
      p {
        padding-top: 6px;
        color: #FFFFFF;
        margin: 0;
      }
    }
  }
}
.type {
  width: 75%;
  margin: 0 auto;
  .typebg {
    background: url(../assets/img/new/zhuzhu/zhuzhu.png);
    background-size: cover;
    .typeon {
      padding: 60px 0 0 0;
      display: flex;
      width: 90%;
      margin: 0 auto;
      .typetext {
        // padding: 3%;
        width: 50%;
        // display: flex;
        // align-items: center;
        h4 {
          padding: 5%;
          font-size: 22px;
          font-weight: 600;
          color: #000000;
          margin: 0;
          // padding-bottom: 5%;
        }
        p {
          padding: 5%;
          background-color: #f5f5f5;
          box-shadow: 0px 2px 6px #bfbfbf;
          border-radius: 12px;
          color: #000000;
        }
      }
      .typeimg {
        padding: 2%;
        width: 50%;
        img {
          width: 85%;
          float: right;
        }
      }
    }
    .typeto {
      padding: 60px 0 0 0;
      display: flex;
      width: 90%;
      margin: 0 auto;
      .typetoimg {
        text-align: center;
        padding: 2%;
        width: 50%;
        img {
          width: 50%;
        }
      }
      .typetocon {
        padding: 3%;
        width: 50%;
        h4 {
          width: 94%;
          float: right;
        }
        .typetotext {
          margin-bottom: 4%;
          width: 96%;
          float: right;
          background-color: #f5f5f5;
          box-shadow: 0px 2px 6px #bfbfbf;
          display: flex;
          padding: 12px 12px 0 12px;
          border-radius: 12px;
          span {
            width: 25%;
            font-size: 14px;
            font-weight: 600;
          }
          p {
            width: 75%;
          }
        }
      }
    }
  }
}
.dp {
  width: 75%;
  margin: 0 auto;
  padding: 60px 0 0 0;
  display: flex;
  align-items: center;
  .dptext {
    padding: 3%;
    width: 60%;
    // display: flex;
    // align-items: center;
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #2161BF;
      padding-bottom: 5%;
    }
    p {
      color: #000000;
    }
  }
  .dpimg {
    padding: 2%;
    width: 40%;
    img {
      width: 100%;
    }
  }
}

</style>