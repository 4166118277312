<template>
  <Header></Header>
  <!-- <banner></banner> -->
  <div class="header">
    <img src="../assets/img/new/letterm/letterm-header.png" alt="">
  </div>
  <div class="conntt">
    <div class="advantage">
      <div class="title">
        <p>产品优势</p>
      </div>
      <div class="item">
        <div class="itemo">
          <div class="itemoimg">功能完备</div>
          <div class="itembg">
            <div class="itemotext">
              <p>监管手段多样</p>
              <p>丰富的统计功能</p>
              <p>支持联保、续保、解保、理赔</p>
              <p>支持电子保函、开放接口</p>
              <p>已实现与极速OA的互联互通</p>
            </div>
            <!-- <div class="itemobgimg">
              <img src="../assets/img/new/letterm/letterm-iconbg.png" alt="">
            </div> -->
            
          </div>
        </div>
        <div class="itemo">
          <div class="itemoimg">公开透明</div>
          <div class="itembg">
            <div class="itemotext">
              <p>敏感操作使用CA签名,全程留痕</p>
              <p>保证人名单、额度信息公开</p>
              <p>面向社会提供查询验真服务</p>
              <p>保函格式备案预置</p>
              <p></p>
            </div>
            <!-- <img src="../assets/img/new/letterm/letterm-iconbg.png" alt=""> -->
          </div>
        </div>
        <div class="itemo">
          <div class="itemoimg">安全可靠</div>
          <div class="itembg">
            <div class="itemotext">
              <p>敏感操作使用CA签名或多因素验证</p>
              <p>用户操作全程留痕</p>
              <p>历经十余年打磨验证</p>
              <p>河南、甘肃等省份成功应用</p>
              <p>可与办理平台同期建设打造管、办一体化平台</p>
            </div>
            <!-- <img src="../assets/img/new/letterm/letterm-iconbg.png" alt=""> -->
          </div>
        </div>
        <div class="itemo">
          <div class="itemoimg">弹性高效</div>
          <div class="itembg">
            <div class="itemotext">
              <p>支持对各地区、各行业监管标准差异化适配</p>
              <p>支持独立UI</p>
              <p>全配置化运行，开箱即用</p>
              <p>多种建设模式可供选择</p>
              <p></p>
            </div>
            <!-- <img src="../assets/img/new/letterm/letterm-iconbg.png" alt=""> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="background-color: #F5F5F5;">
    <div class="ad">
      <p class="adtext">
        电子保函管、办一体化平台是思创网联公司深度研究我国工程担保行业管理现状与特点之后设计的适用于各个行业的管理系统软件，可结合电子保函的各种不同应用场景，综合运用行业领先的互联网技术，着力围绕提升管理效能、优化办理流程、提升管理透明度等目标，构建了一套功能完备、公开透明、安全可靠、弹性高效的软件系统。满足各行业管理需求、涉及全品类电子保函的专业化管理、电子化办理的一体化服务平台。该平台对开展电子保函业务的担保人实行登记管理、额度控制、应急风险管理、行为监测、格式条款控制等手段，在满足交易中心电子保函业务实际需求的同时，又允许各行业主管部门根据行业管理的要求独立设置本行业内保证人监管名单、监管策略、保函监管条件、保函备案审核等策略，开创了中心化监管与行业主管相结合的全新电子化保函监管模式。
      </p>
      <div class="adimg">
        <img src="../assets/img/new/letterm/letterm-ad.png" alt="">
      </div>
    </div>
  </div>
  <div class="system">
    <div class="title">
      <p>平台体系</p>
    </div>
    <div class="systemimg">
      <img src="../assets/img/new/letterm/letterm-system.png" alt="">
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/header.vue";
// import Banner from "@/components/banner.vue";
import Footer from "@/components/footer.vue";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  components: {
    Header,
    // Banner,
    Footer,
  },

  setup() {
    onMounted(() => {
      _loadNewPage()
    })
    const _loadNewPage = () => {
       window.scrollTo(0, 0); // 滚动条回到页面顶端(x坐标, y坐标)
    }
    return {
      _loadNewPage,
    }
  },
});
</script>

<style lang="less" scoped>
/* 屏幕大于1200px显示的颜色 */

/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width: 1200px) {

}
/* 小屏幕 768px-992px*/
@media screen and (max-width: 992px) {
  
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突,怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width: 767px) {
  .item {
    display: block !important;
    .itemo {
      width: 100% !important;
    }
    .itemt {
      width: 100% !important;
    }
    .itemth {
      width: 100% !important;
    }
    .itemf {
      width: 100% !important;
    }
  }
  .ad {
    display: block !important;
    .adimg {
      width: 100% !important;
    }
    .adtext {
      width: 100% !important;
    }
  }
}
// 公共css
h4 {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  padding-bottom: 5%;
}
p {
  color: #000000;
}
.title {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}
.title p {
  padding: 60px 0 0 0 ;
  font-size: 32px;
  color: #2161BF;
  font-weight: 800;
}
// 小块
.header {
  padding: 50px 0 0 0;
}
.header img {
  width: 100%;
}
.conntt {
  padding-top: 40px;
  width: 100%;
  background: url(../assets/img/new/letterm/letterm-adbg.png);
  background-size: cover;
}
.advantage {
  width: 75%;
  margin: 0 auto;
  padding-bottom: 100px;
  .item {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 30px;
    .itemo {
      // display: flex;
      width: 23%;
      height: 420px;
      border-radius: 12px;
      background-color: #FFFFFF;
      box-shadow: 0px 2px 6px #bfbfbf;
      .itemoimg {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        background-color: #5887dd;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        // background: url(../assets/img/new/zhuzhu/zhuzhu-icon1.png);
        // background-size: cover;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .itembg {
        background: url(../assets/img/new/letterm/letterm-iconbg.png);
        // background-size: cover;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        // background-color: beige;
        height: 80%;
        background-position: left bottom;
        background-repeat: no-repeat;
        img {
          width: 70%;
          margin-top: -110px;
        }
      }
      .itemotext {
        text-align: center;
        padding: 40px 12%;
        height: 85%;
        p {
          font-weight: 600;
        }
      }
    }
    .itemt {
      // display: flex;
      width: 23%;
      border-radius: 12px;
      background-color: #FFFFFF;
      .itemoimg {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        background-color: #2b8bff;
        border-radius: 12px;
        // background: url(../assets/img/new/zhuzhu/zhuzhu-icon2.png);
        // background-size: cover;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .itembg {
        // background: url(../assets/img/new/letterm/letterm-iconbg.png);
        // background-size: cover;
        background-color: beige;
        img {
          margin-top: -110px;
        }
      }
      .itemotext {
        text-align: center;
        padding: 40px 12%;
        p {
          font-weight: 600;
        }
      }
    }
    .itemth {
      // display: flex;
      width: 23%;
      border-radius: 12px;
      background-color: #FFFFFF;
      .itemoimg {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        background-color: #4189df;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        // background: url(../assets/img/new/zhuzhu/zhuzhu-icon3.png);
        // background-size: cover;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .itembg {
        // background: url(../assets/img/new/letterm/letterm-iconbg.png);
        // background-size: cover;
        background-color: beige;
        img {
          margin-top: -110px;
        }
      }
      .itemotext {
        text-align: center;
        padding: 40px 12%;
        p {
          font-weight: 600;
        }
      }
    }
    .itemf {
      // display: flex;
      width: 23%;
      border-radius: 12px;
      background-color: #FFFFFF;
      .itemoimg {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        background-color: #009fde;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        // background: url(../assets/img/new/zhuzhu/zhuzhu-icon4.png);
        // background-size: cover;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .itembg {
        // background: url(../assets/img/new/letterm/letterm-iconbg.png);
        // background-size: cover;
        background-color: beige;
        img {
          margin-top: -110px;
        }
      }
      .itemotext {
        text-align: center;
        padding: 40px 12% 0 40px;
        p {
          font-weight: 600;
        }
      }
    }
  }
}
.ad {
  padding: 80px 0 0 0;
  display: flex;
  width: 75%;
  margin: 0 auto;
  .adimg {
    padding: 2%;
    width: 50%;
    img {
      width: 90%;
    }
  }
  .adtext {
    padding: 3%;
    width: 50%;
    display: flex;
    align-items: center;
  }
}
.system {
  width: 75%;
  margin: 0 auto;
  padding: 80px 0;
  .systemimg {
    width: 80%;
    margin: 80px auto 40px auto;
    text-align: center;
  }
  .systemimg img {
    width: 100%;
  }
}
.tead {
  background-color: #F5F5F5;
  width: 100%;
  .teadcon {
    display: flex;
    .teadimg {
      width: 60%;
      img {
        width: 100%;
      }
    }
    .teadtext {
      width: 40%;
      margin: auto 0 auto 40px;
      p {
        background: #FFFFFF;
        padding: 20px 20px 20px 40px;
        border-radius: 12px;
        width: 56%;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 30px;
      }
    }
  }
}

</style>