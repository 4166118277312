import moment from "moment"
moment.locale('zh-cn');

const state = {
  siteConfigs: {
    lastUpdateAt: null,
    data: {
      COMPANY_NAME: '思创网联（北京）科技发展有限公司',
      COMPANY_ADDRESS: '地址(河南)：河南省郑州市金水路288号曼哈顿广场C区11号商务楼2401',
      COMPANY_ADDRESS_BEIJINNG: '地址(北京): 北京市海淀区广源闸5号1幢7层701-217',
      COMPANY_TEL: '客服电话：400-811-5272',
      COMPANY_STARTYEAR: '思创网联2015-',
      COMPANY_NO: ' 京ICP备16022875号',
      COMPANY_QR: '../assets/img/QR.jpg'
    }
  }
}
const mutations = {
  SET_CONFIGS (state, data) {
    state.siteConfigs.data = data
    state.siteConfigs.lastUpdateAt = new Date().toISOString()
    window.localStorage.setItem('site-configs', JSON.stringify(state.siteConfigs))
  },
}
const actions = {
  setConfigs ({ commit }, data) {
    commit('SET_CONFIGS', data)
  },
}
const getters = {
  sysConfigs: (state) => {
    try {
      let localStorageSysConfigs = JSON.parse(window.localStorage.getItem('site-configs'))
      if (localStorageSysConfigs && localStorageSysConfigs.data && localStorageSysConfigs.data.COMPANY_NAME) {
        if (!localStorageSysConfigs.lastUpdateAt || moment().diff(moment(localStorageSysConfigs.lastUpdateAt), 'hours') >= 1) {
          // 过期
          console.log('1-1:过期')
          window.localStorage.removeItem('site-configs')
          return updateSysConfigs()
        }
        // if (localStorageSysConfigs.data.MAINTAINING === true) {
        //   if (window.location.href.indexOf('maintaining.html') < 0) {
        //     window.location.href = '/maintaining.html'
        //   }
        // console.log('1-2 维护')
        // }
        // 
        return localStorageSysConfigs.data
      }
      else {
        if (!state.siteConfigs.lastUpdateAt || moment().diff(moment(state.siteConfigs.lastUpdateAt), 'hours') >= 1) {
          // 过期
          console.log('2-1')
          window.localStorage.removeItem('site-configs')
          updateSysConfigs()
        }
        return state.siteConfigs.data
      }
    } catch (err) {
      console.log('e',err)
      // window.localStorage.removeItem('site-configs')
    }
  }
}
const updateSysConfigs = async () => {
  let configsStr = ''
  configsStr = state.siteConfigs.data
  mutations.SET_CONFIGS(state, configsStr)
}

export default {
  state,
  mutations,
  actions,
  getters,
}