import { createRouter, createWebHistory } from 'vue-router'
import index from "../views/index.vue";
// import Home from "../views/Home.vue";
// import login from "../views/Login.vue";
import about from "../views/about.vue"
import letterh from "../views/letterh.vue"
import letterm from "../views/letterm.vue"
import zhuzhu from "../views/zhuzhu.vue"
import fast from "../views/fast.vue"
import join from "../views/join.vue"
// import alarm from "../views/Monitor.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: '欢迎访问思创网联'
    }
  },
  {
    path: "/about",
    name: "about",
    component: about,
    meta: {
      title: '关于我们'
    }
  },
  {
    path: "/letterh",
    name: "letterh",
    component: letterh,
    meta: {
      title: '电子保函办理平台 | 思创网联'
    }
  },
  {
    path: "/letterm",
    name: "letterm",
    component: letterm,
    meta: {
      title: '电子保函管办一体化平台 | 思创网联'
    }
  },
  {
    path: "/zhuzhu",
    name: "zhuzhu",
    component: zhuzhu,
    meta: {
      title: '助筑通 | 思创网联'
    }
  },
  {
    path: "/fast",
    name: "fast",
    component: fast,
    meta: {
      title: '极速OA | 思创网联'
    }
  },
  {
    path: "/join",
    name: "join",
    component: join,
  }
]

const router = createRouter({
  history: createWebHistory('/official/'),
  routes
})

router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title
  } else {
    document.title = '思创网联'
  }
})

export default router
