<template>
  <Header></Header>
  <!-- <banner></banner> -->
  <div class="header">
    <img src="../assets/img/new/letterh/letterh-header.png" alt="">
  </div>
  <div class="characteristic">
    <div class="title">
      <p>产品特性</p>
    </div>
    <div class="main">
      <div class="row">
        <div class="rowcon">
          <img src="../assets/img/new/letterh/letterh-icon1.png" alt="">
          <p>唯一性</p>
        </div>
        <div class="rowcon">
          <img src="../assets/img/new/letterh/letterh-icon2.png" alt="">
          <p>真实性</p>
        </div>
        <div class="rowcon">
          <img src="../assets/img/new/letterh/letterh-icon3.png" alt="">
          <p>保密性</p>
        </div>
        <div class="rowcon con">
          <img src="../assets/img/new/letterh/letterh-icon4.png" alt="">
          <p>可追溯</p>
        </div>
        <div class="rowcon">
          <img src="../assets/img/new/letterh/letterh-icon5.png" alt="">
          <p>易理赔</p>
        </div>
      </div>
    </div>
  </div>
  <div style="background-color: #F5F5F5;margin-top:60px;">
    <div class="ad">
      <div class="adimg">
        <img src="../assets/img/new/letterh/letterh-ad.png" alt="">
      </div>
      <p class="adtext">
        思创电子投标保函管理平台是由思创网联(北京)科技发展有限公司自主研发的，深度聚焦招投标领域的投标保函办理、管理、监管一体化服务平台。为了响应国家深化“放管服”改革、优化营商环境的要求，思创电子投标保函管理平台已与国家相关政策文件规定的担保机构：银行、保险公司、专业的担保公司进行无缝对接。银行电子投标保函、担保公司电子投标保函、保险公司电子投标保单都可以在线办理。思创电子投标保函管理平台为政府部门提供了有力地监管手段，并对现有的电子投标保函市场进行有效的规范，提高监管部门的工作效率。思创电子投标保函管理平台确保入驻的担保机构合法合规，接受主管部门的监管，从而确保投保人和招标人的权益有保障。从平台出具的保函具备唯一性、真实性、保密性、可追溯、易理赔等特性。平台同时提供查询、验真服务。思创电子投标保函管理平台是全国首家通过等保测评的针对电子保函管理的平台。
      </p>
    </div>
  </div>
  <div class="function">
    <div class="title">
      <p style="padding-top: 15%;">产品优势</p>
    </div>
    <div class="funitem">
      <div class="funitemo">
        <span>理念先进</span>
        <p>深度聚焦招投标领域的投标保函办理、管理一体化服务平台，不只是开立电子保函的平台，还可以优化管理保函市场、防范保函风险，同时还提供保函验真服务。
        </p>
      </div>
      <div class="funitemo">
        <span>安全可靠</span>
        <p>保证入驻平台的担保机构开具的电子保函真实有效，一旦发生风险可及时赔付。办理过程全程加密，系统自动留痕。
        </p>
      </div>
      <div class="funitemo">
        <span>全渠道整合</span>
        <p>
          思创电子投标保函管理平台作为一个开放的平台具备银行、保险、专业工程担保公司全渠道整合的能力。目前已对接多家银行、保险公司、专业的工程担保机构。
        </p>
      </div>
      <div class="funitemo">
        <span>免费搭建</span>
        <p>平台免费为公共资源交易中心提供服务。本平台系统从对接、搭建、到正式上线总计划工期为10个工作日左右。
        </p>
      </div>
      <div class="funitemo">
        <span>简单高效</span>
        <p>优化UI设计,突出用户体验,全部硬件设备在线智能化部署。客户只需登录平台或者在办理投标递交投标保证金环节选择递交电子保函,即可办理。全程网上办理24小时内即可出函。
        </p>
      </div>
      <div class="funitemo">
        <span>风险防范</span>
        <p>实时管理担保机构担保额度。一旦担保机构开具超出担保机构本身所承担额度的保函，平台的风险防范机制立即启动，限制其保函办理，为投标人推荐其他担保机构，降低系统风险。
        </p>
      </div>
    </div>
  </div>
  <div class="systembg">
    <div class="system">
      <div class="title">
        <p style="color: #ffffff">办理流程</p>
      </div>
      <div class="systemimg">
        <img src="../assets/img/new/letterh/letterh-system.png" alt="">
      </div>
    </div>
  </div>
  <div class="systemt">
    <div class="title">
      <p>主体构成</p>
    </div>
    <div class="systemimgt">
      <img src="../assets/img/new/letterh/letterh-system2.png" alt="">
    </div>
  </div>
  <div class="bottomimg">
    <img src="../assets/img/new/letterh/letterh-bottomimg.png" alt="">
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  components: {
    Header,
    Footer,
  },

  setup() {
    onMounted(() => {
      _loadNewPage()
    })
    const _loadNewPage = () => {
       window.scrollTo(0, 0); // 滚动条回到页面顶端(x坐标, y坐标)
    }
    return {
      _loadNewPage,
    }
  },
});
</script>

<style lang="less" scoped>
/* 屏幕大于1200px显示的颜色 */
.row {
  display: flex;
  width: 80%;
  margin: 0 auto;
  .rowcon {
    width: 20%;
  }
  // .rowcon img {
  //   // width: 70%;
  // }
  .con {
    margin-left: 0;
  }
}

/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width: 1200px) {
  .row {
    display: flex;
    width: 80%;
    margin: 0 auto;
    .rowcon {
      width: 20%;
    }
    // .rowcon img {
    //   width: 80%;
    // }
    .con {
      margin-left: 0;
    }
  }
}
/* 小屏幕 768px-992px*/
@media screen and (max-width: 992px) {
  .row {
    display: inline-block;
    width: 100%;
    .rowcon {
      width: 33%;
      float: left;
    }
    .con {
      margin-left: 16.5%;
    }
  }
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突，怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width: 767px) {
  .row {
    display: inline-block;
    width: 100%;
    .rowcon {
      width: 33%;
      float: left;
    }
    .con {
      margin-left: 16.5%;
    }
  }
  .ad {
    display: block !important;
    .adimg {
      width: 100% !important;
    }
    .adtext {
      width: 100% !important;
    }
  }
}
// 公共css
p {
  color: #000000;
  margin: 0;
}
.title {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}
.title p {
  padding: 60px 0 0 0 ;
  font-size: 32px;
  color: #2161BF;
  font-weight: 800;
}
// 小块
.header {
  padding: 50px 0 0 0;
}
.header img {
  width: 100%;
}
.characteristic {
  width: 75%;
  margin: 0 auto;
  padding: 0 15px;
  .title {
    padding-top: 90px;
    text-align: center;
  }
  .title img {
    width: 200px;
  }
  .main {
    padding-top: 24px;
    font-size: 14px;
    text-align: center;
    .maintext {
      color: #787878;
    }
    .row {
      .rowcon p {
        color: #2161BF;
        font-weight: 600;
        padding-top: 10px;
        font-size: 16px;
      }
    }
  }
}
.ad {
  padding: 40px 0 0 0;
  display: flex;
  width: 75%;
  margin: 0 auto;
  .adimg {
    padding: 2%;
    width: 50%;
    img {
      width: 100%;
    }
  }
  .adtext {
    padding: 3%;
    width: 50%;
    display: flex;
    align-items: center;
    text-align: justify;
  }
}
.function {
  background: url(../assets/img/new/letterh/letterh-functionbg.png);
  background-size: cover;
  // background-size: contain;
  width: 75%;
  margin: -40px auto 0px auto;
  .funitem {
    // width: 75%;
    // margin: 0 auto;
    // padding-bottom: 80px;
    padding: 28% 0 5% 0;
    display: flex; 
    justify-content: space-between;
    .funitemo {
      background-color: #FFFFFF;
      width: 16%;
      // margin: 0 5px;
      padding: 3% 20px;
      border-radius: 12px;
      display: inline-block;
      text-align: center;
      box-shadow: 0px 2px 6px #bfbfbf;
      span {
        color: #000000;
        font-size: 15px;
        padding-top: 10px;
        font-weight: 600;
      }
      p {
        padding-top: 30px;
        color: #000000;
        margin: 0;
        text-align: justify;
        // font-size: 13px;
      }
    }
  }
}
.systembg {
  width: 100%;
  background: url(../assets/img/new/letterh/letterh-systembg.png);
  background-size: cover;
}
.system {
  // background: url(../assets/img/new/letterh/letterh-systembg.png);
  // background-size: cover;
  width: 75%;
  margin: 0 auto 60px auto;
  padding-bottom: 60px;
  // margin: 60px 0;
  .systemimg {
    width: 80%;
    margin: 80px auto 40px auto;
    text-align: center;
  }
  .systemimg img {
    width: 90%;
  }
}
.systemt {
  // background: url(../assets/img/new/letterh/letterh-functionbg.png);
  // background-size: cover;
  width: 75%;
  margin: 60px auto;
  // margin: 60px 0;
  .systemimgt {
    width: 80%;
    margin: 80px auto 40px auto;
    text-align: center;
  }
  .systemimgt img {
    width: 70%;
  }
}
.bottomimg {
  width: 100%;
}
.bottomimg img {
  width: 100%;
  margin: -30% 0 0 0;
}
</style>