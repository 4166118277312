<template>
  <Header></Header>
  <!-- <banner></banner> -->
  <div class="header">
    <img src="../assets/img/new/fast/fast-header.png" alt="">
  </div>
  <div class="conntt">
    <div class="advantage">
      <div class="title">
        <p>产品优势</p>
      </div>
      <div class="item">
        <div class="itemo">
          <div class="itemotitle">量身打造</div>
          <div class="itemoimg">
            <img src="../assets/img/new/fast/fast-icon1.png" alt="">
          </div>
          <div class="itemotext">
            <p>担保业务全覆盖、整合保函业务流程</p>
            <p>电子保函、电子合同</p>
            <p>支持审理会、董事会</p>
            <p>商业分析（BI）功能、业务报表</p>
          </div>
        </div>
        <div class="itemo">
          <div class="itemotitle">成熟可靠</div>
          <div class="itemoimg">
            <img src="../assets/img/new/fast/fast-icon2.png" alt="">
          </div>
          <div class="itemotext">
            <p>经过十余家行业头部企业成功应用</p>
            <p>已形成一整套工作流程</p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="itemo">
          <div class="itemotitle">高效互联</div>
          <div class="itemoimg">
            <img src="../assets/img/new/fast/fast-icon3.png" alt="">
          </div>
          <div class="itemotext">
            <p>线上收单/办理</p>
            <p>行业管理系统</p>
            <p>联保分保系统</p>
            <p></p>
          </div>
        </div>
        <div class="itemo">
          <div class="itemotitle">多端部署</div>
          <div class="itemoimg">
            <img src="../assets/img/new/fast/fast-icon4.png" alt="">
          </div>
          <div class="itemotext">
            <p>Web</p>
            <p>小程序</p>
            <p>消息推送</p>
            <p>APP（安卓、iOS）</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="background-color: #F5F5F5;margin-top:60px;">
    <div class="ad">
      <div class="adimg">
        <img src="../assets/img/new/fast/fast-ad.png" alt="">
      </div>
      <p class="adtext">
        担保企业办公自动化平台通过现代通信、网络、数据库等新一代技术方案，将企业的业务流程、经营管理、品牌运营以及营销推广等线下的业务流程集中到信息化软件或信息化技术平台上，实现无纸化、网络化、自动化的办公、业务处理和数据存储机制。同时，担保企业办公自动化平台也能调整企业原有的业务流程、经营管理模式、思想文化建设、人员组织架构和研发生产标准等，创建或重塑一种新的商业模式，实现商业上的创新。担保企业办公自动化平台是为担保行业量身打造的办公自动化工具，产品成熟可靠、高效互联、多端部署，且拥有强大的技术特性。提高企业的整体运转效率，降低人力、时间等成本，并通过自动化提高业务的可靠性。
      </p>
    </div>
  </div>
  <div class="system">
    <div class="title">
      <p>平台体系</p>
    </div>
    <div class="systemimg">
      <img src="../assets/img/new/fast/fast-system.png" alt="">
    </div>
  </div>
  <div class="tead">
    <div class="title">
      <p>技术优势</p>
    </div>
    <div class="teadcon">
      <div class="teadimg">
        <img src="../assets/img/new/fast/fast-tead.png" alt="">
      </div>
      <div class="teadtext">
        <p>跨平台 一套代码、多端部署</p>
        <p>CD/CI (持续部署/持续集成)</p>
        <p>容器化、不依赖操作系统</p>
        <p>高效的工作流引擎</p>
        <p>强大的表单设计器</p>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/header.vue";
// import Banner from "@/components/banner.vue";
import Footer from "@/components/footer.vue";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  components: {
    Header,
    // Banner,
    Footer,
  },

  setup() {
    onMounted(() => {
      _loadNewPage()
    })
    const _loadNewPage = () => {
       window.scrollTo(0, 0); // 滚动条回到页面顶端(x坐标, y坐标)
    }
    return {
      _loadNewPage,
    }
  },
});
</script>

<style lang="less" scoped>
/* 屏幕大于1200px显示的颜色 */

/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width: 1200px) {

}
/* 小屏幕 768px-992px*/
@media screen and (max-width: 992px) {
  
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突,怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width: 767px) {
  .item {
    display: block !important;
    .itemo {
      width: 100% !important;
    }
    .itemt {
      width: 100% !important;
    }
    .itemth {
      width: 100% !important;
    }
    .itemf {
      width: 100% !important;
    }
  }
  .ad {
    display: block !important;
    .adimg {
      width: 100% !important;
    }
    .adtext {
      width: 100% !important;
    }
  }
}
// 公共css
h4 {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  padding-bottom: 5%;
}
p {
  color: #000000;
}
.title {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}
.title p {
  padding: 60px 0 0 0 ;
  font-size: 32px;
  color: #2161BF;
  font-weight: 800;
}
// 小块
.header {
  padding: 50px 0 0 0;
}
.header img {
  width: 100%;
}
.conntt {
  width: 100%;
}
.advantage {
  width: 75%;
  margin: 0 auto;
  .item {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    .itemo {
      // display: flex;
      padding: 42px 0;
      width: 24%;
      box-shadow: 0px 2px 6px #bfbfbf;
      border-radius: 12px;
      .itemotitle {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
      .itemoimg {
        padding: 20px 0;
        width: 100%;
        text-align: center;
        // img {
        //   width: 50%;
        //   max-height: 120px;
        // }
      }
      .itemotext {
        padding: 0 6%;
        text-align: center;
        line-height: 2;
        // margin-bottom: 40px;
        p {
          margin: 0;
        }
      }
    }
  }
}
.ad {
  padding: 80px 0 0 0;
  display: flex;
  width: 75%;
  margin: 0 auto;
  .adimg {
    padding: 2%;
    width: 50%;
    img {
      width: 100%;
    }
  }
  .adtext {
    padding: 3%;
    width: 50%;
    display: flex;
    align-items: center;
  }
}
.system {
  width: 75%;
  margin: 0 auto;
  .systemimg {
    width: 80%;
    margin: 10px auto 40px auto;
    text-align: center;
  }
  .systemimg img {
    width: 100%;
  }
}
.tead {
  background-color: #F5F5F5;
  width: 100%;
  .teadcon {
    display: flex;
    justify-content: space-evenly;
    .teadimg {
      // width: 60%;
      img {
        width: 100%;
      }
    }
    .teadtext {
      width: 40%;
      margin: auto 0 auto 40px;
      p {
        box-shadow: 0px 2px 6px #bfbfbf;
        background: #FFFFFF;
        padding: 20px 20px 20px 40px;
        border-radius: 12px;
        width: 56%;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 30px;
      }
    }
  }
}

</style>