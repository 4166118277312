<template>
  <Header></Header>
  <!-- <banner></banner> -->
  <div class="header">
    <img src="../assets/img/new/about/about-header.png" alt="">
  </div>
  <div class="us">
    <div class="ustitle">
      <p>公司简介</p>
    </div>
    <div class="ustext">
      <p>思创网联(北京)科技发展有限公司2012年建立。致力构建国内先进的电子保函数智化平台和相关应用软件,我们以专业的行业管理经验、权威的信息来源、优质的技术服务,为建设工程领域各方参与主体提供一站式的数字化解决方案。目前已服务客户20000余家受到广大用户的一致好评!</p>
      <p>思创网联(北京)科技发展有限公司是由一支精英技术团队和年轻,富有活力的运维团队组成的高科技公司,公司有博士1人、硕士研究生3人,员工90%以上拥有本科学历。我们重承创新、诚信、钻研、细致的企业文化,在当今全面数字化发展的大环境下,用科技的力量为企业赋能,坚持合规、创新、服务的理念,以行业领军者的姿态推动工程担保领域“石联网+”的健康发展,践行科技报国的理相,全力打造工程担保绿色数智化生态系统。</p>
    </div>
  </div>
  <div class="conntt" >
    <div class="usaboutimg">
      <img src="../assets/img/new/about/about-us.png" alt="">
    </div>
    <div class="usflex"></div>
    <div class="usexperience">
      <img src="../assets/img/new/about/about-experience.png" alt="">
    </div>
    <div class="usimg">
      <img src="../assets/img/new/about/about-usimg.png" alt="">
    </div>
  </div>
  <div class="aptitude">
    <div class="ustitle">
      <p>企业资质</p>
    </div>
    <div class="aptitudeimg">
      <img src="../assets/img/new/about/about-aptitude.png" alt="">
    </div>
  </div>
  <div class="culture">
    <div class="culturetext">
      <p>让建设领域借助大数据、物联网等信息化工具更简单、高效！为推进中国建筑业进入信息化时代贡献力量！</p>
    </div>
    <div class="cultureimg">
      <img src="../assets/img/new/about/about-culture.png" alt="">
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/header.vue";
// import Banner from "@/components/banner.vue";
import Footer from "@/components/footer.vue";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  components: {
    Header,
    // Banner,
    Footer,
  },

  setup() {
    onMounted(() => {
      _loadNewPage()
    })
    const _loadNewPage = () => {
       window.scrollTo(0, 0); // 滚动条回到页面顶端(x坐标, y坐标)
    }
    return {
      _loadNewPage,
    }
  },
});
</script>

<style lang="less" scoped>
/* 屏幕大于1200px显示的颜色 */
.secon {
  display: flex;
  justify-content: center;
  .seconimg {
    width: 42%;
  }
  .secontext {
    width: 50%;
  }
}
.aditem {
  display: flex;
  .item {
    width: 33.3%;
  }
}
.ustext {
  color: #4C4C4C;
  text-align: center;
  width: 76%;
  margin: 0 auto;
}
/* 中等屏幕 992px-1200px 屏幕宽度在1200px以下显示的颜色*/
@media screen and (max-width: 1200px) {
  .secon {
    display: flex;
    justify-content: center;
    .seconimg {
      width: 42%;
    }
    .secontext {
      width: 50%;
    }
  }
  .aditem {
    display: flex;
    .item {
      width: 33.3%;
    }
  }
  .ustext {
    color: #4C4C4C;
    text-align: center;
    width: 76%;
    margin: 0 auto;
  }
}
/* 小屏幕 768px-992px*/
@media screen and (max-width: 992px) {
  .secon {
    display: block;
    .seconimg {
      width: 100%;
    }
    .secontext {
      width: 100%;
    }
  }
  .aditem {
    display: block;
    .item {
      width: 100%;
    }
  }
  .ustext {
    width: 76%;
    margin: 0 auto;
  }
}
/* 注意：此时超小屏幕和小屏幕在768px之间有冲突,怎么解决？
把超小屏幕的区间降低1px*/
/* 超小屏幕 768px屏幕以下*/
@media screen and (max-width: 767px) {
  .secon {
    display: block;
    .seconimg {
      width: 100%;
    }
    .secontext {
      width: 100%;
    }
  }
  .aditem {
    display: block;
    .item {
      width: 100%;
    }
  }
  .ustext {
    width: 100%;
    margin: 0 auto;
  }
}
// 公共css
.ustitle {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}
.ustitle p {
  padding: 60px 0 0 0 ;
  font-size: 32px;
  color: #2161BF;
  font-weight: 800;
}
// .arrow {
// }
// 小块
.header {
  padding: 50px 0 0 0;
}
.header img {
  width: 100%;
}
.us {
  width: 80%;
  margin: 0 auto;
}
.ustitle {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}
.ustitle p {
  padding: 60px 0 0 0 ;
  font-size: 32px;
  color: #2161BF;
  font-weight: 800;
}
.ustitle img {
  width: 100px;
}
.ustext {
  color: #4C4C4C;
  text-align: center;
  // width: 76%;
  // margin: 0 auto;
}
.ustext p {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.conntt {
  width: 100%;
}
.usaboutimg {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  // margin-bottom: 60px;
  text-align: center;
}
.usaboutimg img {
  width: 70%;
  float: right;
}
.usflex {
  width: 100%;
  background-color: #2161BF;
  height: 40px;
  margin-top: -12px;
}
.usexperience {
  // display: inline-block;
  width: 70%;
  margin: 80px auto;
  // margin-bottom: 60px;
  text-align: center;
}
.usexperience img {
  width: 100%;
  // max-height: 400px;
}
.usimg {
  width: 100%;
}
.usimg img {
  width: 100%;
}
.aptitude {
  width: 80%;
  margin: 0 auto;
}
.aptitudeimg {
  width: 70%;
  margin: 80px auto;
  text-align: center;
}
.aptitudeimg img {
  width: 100%;
}
.culture {
  background: url(../assets/img/new/about/about-culturebg.png) center center no-repeat;
  background-size: cover;
  margin-top: 106px;
  // max-height: 760px;
  // margin: 0 auto;
  // padding: 0 20px;
  // width: 80%;
  .culturetext {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding-top: 86px;
  }
  .culturetext h4 {
    padding-bottom: 44px;
    font-size: 20px;
    color: #00a88e;
  }
  .culturetext p {
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    margin: 0;
  }
  .cultureimg {
    width: 70%;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
  }
  .cultureimg img {
    width: 52%;
  }
}
</style>