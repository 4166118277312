import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from "ant-design-vue"
import "./assets/css/antd.min.css"
import axios from "axios"
// import moment from 'moment'
// import 'moment/dist/locale/zh-cn'
// import Vue from 'vue';
// import "swiper/swiper.min.css"
// import Swiper from 'swiper'
// import './assets/js/swiper-element-bundle.min.js'
// import Swiper from 'swiper/vue'
// import './assets/css/swiper.min.css'
// import 'swiper/css/swiper.css'

// import 'swiper/swiper-bundle.css'
// import Api from './api/Api'
// import common from './utils/common'


// createApp(App).use(store).use(router).mount('#app')
const app = createApp(App);
app.use(store);
app.use(router);
app.use(Antd);
// app.use(Swiper);
app.mount("#app");
// app.use(moment().format())
// moment().format(); 
// app.$moment = moment
// app.prototype.$axios = axios;
// app.prototype.$Api = Api

// const instance = new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");
axios.interceptors.request.use(
    config => {
      if (!config.headers.Authorization) {
        // if (instance) {
        //   let tokenFromStore = instance.$store.getters.token
        //   let tokenFromLocalStorage = window.localStorage.getItem('token')
        //   // console.log(token)
        //   let token = tokenFromStore ||tokenFromLocalStorage
        //   if (token && token !== null && config.url.indexOf('/api/Account/Login') === -1) {
        //     config.headers.Authorization = 'Bearer ' + token
        //   }
        // }
      }
      return config
    },
    err => {
      return Promise.reject(err)
    }
  )
  
  // http response 拦截器
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      // let config = error.config
      // console.log(config)
      if (error.response) {
        // console.log(error.request, error.response.status)
        // switch (error.response.status) {
        //   case 401:
        //     instance.$Spin.hide()
        //     instance.$Notice.error({
        //       title: '您还未登录，请先登录',
        //       desc: ''
        //     })
        //     router.push({ path: '/login' })
        //     break
        //   case 406:
        //     router.back()
        //     instance.$Spin.hide()
        //     instance.$Notice.error({
        //       title: '您没有访问该资源的权限！',
        //       desc: ''
        //     })
        //     break
        // }
      }
      return Promise.reject(error.response.data.message)   // 返回接口返回的错误信息
    }
  )
  
//   Vue.prototype.$vuescrollConfig = {
//     bar: {
//       background: '#777'
//     }
//   }
  
//   let instance = new Vue({
//     router,
//     store,
//     render: h => h(App),
//   }).$mount('#app')
  